import { isValidElement, useMemo, useState, useRef } from 'react'

import { PLACENAME_SEARCH } from '@avcan/constants/products/mixpanel.js'
import { noop } from '@avcan/utils/function'
import { isObject } from '@avcan/utils/object'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'

import { useSearchTerm } from 'clients/geocoder'
import { Input } from 'components/controls'
import { OptionSet } from 'components/controls/options'
import { Place } from 'components/icons'
import { PRIMARY } from 'constants/colors'
import * as MapContext from 'contexts/map'
import * as MapState from 'contexts/map/state'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import localPlaces from 'components/controls/MapGeocoder/localPlaces.json'
import { calculateSelectedIndex, useMapGeocoder } from 'components/controls/MapGeocoder/useMapGeocoder'
import { useSetGeolocation, useGeolocation } from 'stores/MapStore'

import css from './MapGeocoder.module.css'

const EARTH_RADIUS = 6371
const MAX_DISTANCE_CAP = 800
const MAX_ZOOM_LEVEL = 20
const USER_LOCATION_WEIGHTING = 1 / MAX_ZOOM_LEVEL
const SCREEN_CENTER_WEIGHTING = 1 / MAX_ZOOM_LEVEL
const MAX_PLACE_RELEVANCE_SCORE = 3.85

export const MapGeocoder = () => {
    const intl = useIntl()
    const { locale } = useRouter()

    const inputRef = useRef(null)

    const map = MapContext.useMap()
    const { center, zoom } = MapState.useMapState()
    const sendTrackEvent = useSendTrackEvent()
    const { term, active, activate, deactivate, selectedIndex, setTerm, onFocus, onKeyDown } = useMapGeocoder(inputRef)
    const { data: results, error } = useSearchTerm(term)
    const isLoading = !results && !error
    const geolocation = useGeolocation()
    const setGeolocation = useSetGeolocation()

    const [lngLat, setLngLat] = useState(null)
    const [localSelectedIndex, setLocalSelectedIndex] = useState(-1)

    const handleFocus = () => {
        console.log('focusing')
        if ('geolocation' in navigator) {
            console.log('we have geolocation')
            navigator.geolocation.getCurrentPosition(position => {
                console.log('position', position)
                setGeolocation(position)
            })
        }

        onFocus()
    }

    const handleChange = value => {
        activate()
        setTerm(value)
    }

    const handleOptionClick = place => {
        let { latitude, longitude, name, lat, lng } = place
        if (!latitude) {
            latitude = lat
        }
        if (!longitude) {
            longitude = lng
        }

        let placeName = name
        if (isObject(name)) {
            placeName = name[locale]
        }

        sendTrackEvent(PLACENAME_SEARCH, {
            place_name: placeName,
            typed_place_name: term || 'EMPTY',
            place_id: place.id,
        })

        deactivate()
        setTerm(placeName)

        setLngLat({ lng: longitude, lat: latitude })
        map.flyTo({
            center: [longitude, latitude],
            zoom: 9,
        })
    }

    const handleClearClick = () => {
        deactivate()
        setTerm('')
        setLngLat(null)
    }

    const handleKeyDown = event => {
        if (term) {
            return onKeyDown(event, handleOptionClick)
        }

        // Down
        if (event.keyCode === 40) {
            setLocalSelectedIndex(calculateSelectedIndex(localSelectedIndex, relevant20Places.length, true))
            return
        }

        // Up
        if (event.keyCode === 38) {
            setLocalSelectedIndex(calculateSelectedIndex(localSelectedIndex, relevant20Places.length, false))
            return
        }

        // Enter
        if (event.keyCode === 13) {
            event.preventDefault()
            handleOptionClick(relevant20Places[localSelectedIndex])
            deactivate()
            setLocalSelectedIndex(0)

            return
        }

        // Escape
        if (event.keyCode === 27) {
            deactivate()
            setLocalSelectedIndex(-1)
            inputRef.current.blur()

            return
        }
    }

    const showResults = active && results && results.length > 0
    const showNoResults = active && Boolean(term) && isLoading
    const showLocalResults = active && !Boolean(term)

    const relevant20Places = useMemo(() => {
        if (!center.value?.lat || !center.value?.lng || !zoom.value) {
            return localPlaces.sort((a, b) => a.relevance - b.relevance).slice(0, 20)
        }

        const userLocation = geolocation
            ? { lat: geolocation?.coords?.latitude, lng: geolocation?.coords?.longitude }
            : null

        return localPlaces
            .sort(
                (a, b) =>
                    calculateScore(b, userLocation, center.value, zoom.value) -
                    calculateScore(a, userLocation, center.value, zoom.value)
            )
            .slice(0, 20)
    }, [geolocation, center, zoom])

    const localSelectedPlace = relevant20Places[localSelectedIndex]

    return (
        <div className={css.Container}>
            <div className={css.Control}>
                <div className={css.Icon}>
                    <Place color={PRIMARY} />
                </div>
                <Input
                    type="text"
                    inputRef={inputRef}
                    className={css.Input}
                    placeholder={intl.formatMessage({
                        description: 'Geocoder',
                        defaultMessage: 'Search Places…',
                    })}
                    value={term}
                    onChange={e => handleChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={deactivate}
                />
            </div>
            {Boolean(term) && (
                <button className={css.Clear} onClick={handleClearClick}>
                    ×
                </button>
            )}
            {showResults && (
                <div className={css.BoxWithShadow}>
                    <OptionSet onChange={handleOptionClick}>
                        {results.map((place, index) => {
                            const selected = index === selectedIndex

                            return (
                                <Option key={place.id} value={place} selected={selected}>
                                    {place.name}
                                </Option>
                            )
                        })}
                    </OptionSet>
                </div>
            )}
            {showNoResults && (
                <div className={clsx(css.BoxWithShadow, css.PaddedText)}>
                    <FormattedMessage defaultMessage="No results found" description="Map geocoder search results" />
                </div>
            )}
            {showLocalResults && relevant20Places.length > 0 && (
                <>
                    <div className={css.BoxWithShadow}>
                        <OptionSet onChange={handleOptionClick}>
                            {relevant20Places.map((place, index) => {
                                const selected = index === localSelectedIndex

                                return (
                                    <Option key={place.id} value={place} selected={selected}>
                                        {place.name}
                                    </Option>
                                )
                            })}
                        </OptionSet>
                    </div>
                    {relevant20Places.map(place => {
                        return (
                            <MapContext.Marker key={place.id} lnglat={{ lng: place.lng, lat: place.lat }}>
                                <Place color={PRIMARY} opacity={0.5} />
                            </MapContext.Marker>
                        )
                    })}
                </>
            )}
            {lngLat && (
                <MapContext.Marker lnglat={lngLat}>
                    <Place color={PRIMARY} />
                </MapContext.Marker>
            )}
            {localSelectedPlace && (
                <MapContext.Marker lnglat={{ lng: localSelectedPlace.lng, lat: localSelectedPlace.lat }}>
                    <Place color={PRIMARY} />
                </MapContext.Marker>
            )}
        </div>
    )
}

const Option = ({ value, selected, onClick = noop, children }) => {
    const title = isValidElement(children) ? value : children
    const cssClassName = selected ? 'Option--Selected' : 'Option'

    const handleMouseDown = () => {
        onClick(value)
    }

    return (
        <div title={title} onMouseDown={handleMouseDown} className={css[cssClassName]}>
            {children}
        </div>
    )
}

// Utils
// calculateDistanceInKm - Haversine formula to calculate distance between two latitude/longitude points
function calculateDistanceInKm(pointA, pointB) {
    const dLat = toRadians(pointB.lat - pointA.lat)
    const dLon = toRadians(pointB.lng - pointA.lng)
    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(pointA.lat)) * Math.cos(toRadians(pointB.lat)) * Math.sin(dLon / 2) ** 2
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return EARTH_RADIUS * c
}

// toRadians - Convert degrees to radians
const toRadians = deg => deg * (Math.PI / 180)

// normalizeDistance - Normalize distances to a score between 0 and 1, capping at MAX_DISTANCE
const normalizeDistance = distance => 1 - Math.min(distance, MAX_DISTANCE_CAP) / MAX_DISTANCE_CAP

// calculateScore - Calculate the score for a place based on relevance, user proximity, and screen center proximity.
// Makes each category out of 1 and then adds them together. The higher the score, the more relevant the place is.
// userDistance and screenCenterDistance are weighted by zoom level. More zoomed in is more importance to both those categories
// and less to relevance
function calculateScore(place, userLocation, screenCenter, zoomLevel) {
    const relevanceWeight = 1.0
    const userDistanceWeight = userLocation ? zoomLevel * USER_LOCATION_WEIGHTING : 0
    const screenCenterDistanceWeight = zoomLevel * SCREEN_CENTER_WEIGHTING

    // Get relevance score
    const relevanceScore = place.relevance / MAX_PLACE_RELEVANCE_SCORE

    // Calculate user proximity score if user location is provided
    const userProximityScore = userLocation ? normalizeDistance(calculateDistanceInKm(place, userLocation)) : 0

    // Calculate screen center proximity score
    const screenCenterProximityScore = normalizeDistance(calculateDistanceInKm(place, screenCenter))

    // Compute final score
    return (
        relevanceWeight * relevanceScore +
        userDistanceWeight * userProximityScore +
        screenCenterDistanceWeight * screenCenterProximityScore
    )
}
